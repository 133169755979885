import React from 'react';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

import IconButton from './IconButton';

const BlogButton = ({ link = 'http://www.hirahata-clinic.or.jp/blog/', size }) => (
  <IconButton
    size={size}
    link={link}
    external="true"
    label="ブログ"
    icon={faCoffee}
    buttonColor="#ffae00"
  />
);

export default BlogButton;
