import React from 'react';
import Flex from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Card from '../components/Card';
import BlogButton from '../components/BlogButton';
import FacebookButton from '../components/FacebookButton';
import TwitterButton from '../components/TwitterButton';

import dr1lg from '../assets/img/dr-1-lg.png';

const Dr = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="院長紹介">
      <Img src={dr1lg} />
      <P>
        <span
          css={`
            font-weight: bold;
          `}
        >
          理事長/院長
          <br />
          平畑 光一(ひらはた こういち)
        </span>
      </P>
      <P>
        山形大学医学部卒業。
        <br />
        東邦大学大橋病院消化器内科で大腸カメラ挿入時の疼痛、胃酸逆流に伴う症状などについて研究。
        <br />
        胃腸疾患のほか、膵炎など、消化器全般の診療にたずさわった。
        <br />
        2008年7月よりヒラハタクリニック院長。
        <br />
        医療向けIT企業（株）メイドインクリニックを設立。
      </P>
      <Card>
        <span>
          日本消化器内視鏡学会専門医
          <br />
          日本医師会認定産業医
          <br />
          日本内科学会認定医
          <br />
          旧通産省認定 第一種情報処理技術者 LPIC Level 1取得
          <br />
          好きなバンド：BUMP OF CHICKEN
        </span>
      </Card>
      <Flex
        justifyAround={size !== 'sm'}
        column={size === 'sm'}
        center={size === 'sm'}
        css={`
          margin: 30px 20px;
        `}
      >
        <div
          css={`
            margin-bottom: 10px;
          `}
        >
          <BlogButton size={size} />
        </div>
        <div
          css={`
            margin-bottom: 10px;
          `}
        >
          <FacebookButton size={size} />
        </div>
        <div
          css={`
            margin-bottom: 10px;
          `}
        >
          <TwitterButton size={size} />
        </div>
      </Flex>
    </SubLayout>
  );
};

export default Dr;
